import { useUserStore } from '@/stores/user.store';

export default defineNuxtRouteMiddleware(async () => {
  const userStore = useUserStore();
  // const organizationStore = useOrganizationStore();
  const { user } = storeToRefs(userStore);

  if (!user.value) {
    clearError();
    return navigateTo('/login');
  }

  if (user.value.organizationId) {
    // await userStore.checkForUnpaidInvoices();
    // await organizationStore.fetchOrganization();
  }
});
